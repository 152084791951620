$brand-1: #550385;
@import url(https://fonts.googleapis.com/css?family=Lato:400,300,700);
@import "//bootstrap/scss/bootstrap";
@mixin styled-scroll() {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f6f6f6;
    border-radius: 3px;
    margin: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: $brand-1;
  }
}

.toast-success {
  background-color: #550385;
}

.ngx-toastr {
  display: block;
}

$theme-colors: (
  "primary": #550385,
);

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .inner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.date_updated {
  font-style: italic;
  margin-bottom: 20px;
  display: block;
}

.brand-1 {
  color: $brand-1;
}

.mb-1 {
  margin-bottom: 1em;
}

body {
  background: #f6f6f6;
  font-family: Lato, "Lato", sans-serif;
}

.site_head {
  height: 6rem !important;

  .navbar {
    background: #fff;
    margin-bottom: 0;
    height: 100%;
  }

  .navbar-brand {
    width: 13rem;
    margin-right: 1.5em;
    padding: 0.5em 0;
    display: inline-block;

    img {
      max-width: 100%;
      height: auto;
    }
  }
}

.central_panel {
  width: 90%;
  margin: 0 auto;
  max-width: 520px;
  margin-top: 5%;
}

.well {
  word-break: break-all;
}
.well--white {
  background: #fff;
}

.well--black {
  background: #111;
  color: #fff;
}

.alert-small {
  padding: 10px;
  width: 100%;
  display: block;
  margin: 0;
  text-align: center;
}
.alert-success {
  color: $brand-1;
  background-color: #f4e2ff;
  border-color: #dcc2eb;
}
.alert-available {
  background: #f1f1f1;
  border: 1px dashed #ddd;
  color: #666;
}
/* BUTTON OVERRIDES */
.btn-primary {
  background-color: $brand-1;
  border-color: $brand-1;

  &:hover,
  &[disabled]:hover,
  &:focus {
    background-color: darken($brand-1, 10);
  }
}

.login {
  .site_logo {
    width: 15em;
    max-width: 50%;
    display: block;
    margin: 0 auto;
    margin-bottom: 2em;
  }

  .well {
    padding: 10%;
  }
}

h1,
.h1 {
  font-weight: 300;
}

a {
  color: $brand-1;
}

thead {
  background: #fff;
}

.consultation {
  width: 100%;
  font-size: 0.9em;
  height: calc(100vh - 6rem);
  margin: 0 auto;
  display: flex;
}

.patient {
  @include clearfix();
  display: grid;
}

.patient_name {
  display: block;

  strong {
    margin-top: 5px;
  }
  h1 {
    margin: 0;
  }

  h3 {
    margin: 1rem 0;
  }
}

.contact {
  margin-top: -5px;
  margin-bottom: 10px;
  float: right;
  display: block;

  .contact-slot {
    display: flex;
    justify-content: space-between;

    button {
      margin-top: 1rem;
    }
  }

  .contact-additional-info {
    display: flex;
    column-gap: 1rem;

    button {
      margin-top: 1rem;
      width: 23rem;
    }
  }
}

.enterprise-coupon {
  margin-top: 10px;
  margin-right: 5px;
  float: left;
  display: block;
  font-size: 15px;

  i {
    font-size: 20px;
  }

  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
}

.video_chat {
  width: 56%;
  position: relative;
  height: 100%;
  background: #fff;
}

.consultation_details {
  width: 44%;
  height: 100%;
  position: relative;
  //  background: #f8f9fb;
  padding: 2em;
  padding-top: 0;
  overflow: hidden;

  &.scrollable {
    overflow-y: auto;
  }

  h4 {
    text-transform: uppercase;
    font-size: 1em;
    font-weight: bold;
  }
}

.end_call {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAABLUlEQVQ4jZ2SPS+EQRSFn3dXbNhIROFjo9kCUaASlYhEq/YTFAr/REKl2UIlKiIqEYkarUJFoUAkCmxslkdhyLtj913Zk0wyM3fOOffeuYlKChPAEjALjAFDQB9QAD6BKvAM3AFXwDlwDDz+KqioI+quneFF3VR7VFBL6lOHYmlcq/2Jeggs04h7oAJcALehzA+gGNowBawB4xFvD7XexO3U71ZkrUoT3nsuOMd4iM4LwCKQS91dNuF1ox5FLjdqOZVJPhXrjbLcibgniVoCtoFXYB84AGop1zxQD/si8BZlNRn6OQCsJzbOYSvUg3AhMvuDXEZsEJgJ+2oQrQHTwHArUleGoMBGyOpH7CzEVlqzskdjtMlolLM47QRR51Ji8+3e//dTtkLpq+0efgEi6tjlGblligAAAABJRU5ErkJggg==")
    50% 50% #ec0e1d no-repeat;
  border: none;
  border-radius: 50%;
  height: 3em;
  width: 3em;
  text-align: center;
}

.video_mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
  color: #fff;
  display: inline-block;
  vertical-align: middle;

  .video_mask_inner {
    text-align: center;
    padding: 20%;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    h2 {
      margin-top: -50px;
      font-size: 24px;
    }
  }
}

.video_wrapper {
  position: relative;
  padding-bottom: 55%; /* 16:9 */
  height: 0;
  background-size: cover;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.video_large {
  position: relative;

  &:before {
    height: 30%;
    content: "";
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }

  .video_meta {
    position: absolute;
    z-index: 2;
    bottom: 2em;
    left: 2em;

    .end_call {
      vertical-align: middle;
      display: inline-block;
      margin-right: 0.5em;
    }

    .video_duration {
      font-weight: 300;
      color: #fff;
      font-size: 2em;
      vertical-align: middle;
      display: inline-block;
    }
  }

  .video_small {
    position: absolute;
    right: 2em;
    border: 1px solid #fff;
    border-radius: 3px;
    bottom: 2em;
    width: 30%;
    z-index: 2;
  }
}

.chat_wrap {
  height: 100%;
  position: absolute;
  padding: 55% 0 13em 0;
  width: 100%;
  bottom: 0;
  left: 0;
  overflow: hidden;

  .chat {
    position: relative;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 2em;
  }

  .chat_row {
    clear: both;
    width: 100%;
    margin-top: 1em;
    @include clearfix;

    time {
      color: #666;
      font-weight: bold;
      text-transform: uppercase;
      float: left;
      padding: 0.75em 1em;
    }

    .message {
      max-width: 70%;
      background: #edebeb;
      border-radius: 5px;
      padding: 0.75em 1em;
      float: left;
      position: relative;
      word-break: break-all;

      &:after {
        border-left: 0.5em solid transparent;
        content: "";
        border-right: 0.5em solid transparent;
        border-top: 1em solid #edebeb;
        position: absolute;
        bottom: 0;
        left: 0;
        margin-bottom: -0.5em;
        transform: rotate(20deg);
      }
    }

    &--sent {
      time {
        float: right;
        text-align: right;
      }

      .message {
        background-color: #009444;
        color: #fff;
        float: right;

        &:after {
          border-top-color: #009444;
          left: auto;
          right: 0;
          transform: rotate(-20deg);
        }
      }
    }

    &--action {
      .message {
        border: 1px solid currentColor;
        color: #5491e5;
        font-weight: bold;
        background: none;

        &:after {
          display: none;
        }
      }

      &.is_rejected {
        .message {
          color: #940000;
        }
      }

      &.is_accepted {
        .message {
          color: #47a007;
        }
      }
    }
  }

  .chat_buttons {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 1em;
  }
  .chat_actions {
    height: 12em;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #c5c5c5;
    padding: 1em;

    .chat_group {
      margin: 0 0 1em 0;
      position: relative;
      @include clearfix();

      input,
      button {
        height: 3em;
        line-height: 3;
        padding: 0 1em;
        border-radius: inherit;
        background: #fff;
        border: none;
      }

      input {
        width: calc(100% - 5em);
        float: left;
        border-radius: 5px 0 0 5px;
      }

      button {
        width: 5em;
        float: right;
        display: block;
        border-radius: 0 5px 5px 0;
        border-left: 1px solid #ccc;
      }
    }
  }

  .btn-o {
    border: 1px solid currentColor;
    background: none;
    padding: 0.5em 0.75em;
    margin-right: 0.2em;
    color: #333;

    &:hover {
      color: #111;
    }
  }
  .btn-o-1 {
    border: 1px solid currentColor;
    background: none;
    padding: 0.5em 0.75em;
    margin-right: 0.2em;
    background: #fff;
    color: $brand-1;
  }
}

.summary p {
  width: 100%;
  overflow-wrap: break-word;
}
p.ng-invalid {
  color: #940000;
}

.submitted {
  .ng-invalid.btn,
  .ng-invalid.form-control,
  textarea.ng-invalid,
  .ng-invalid.validation:before {
    background: #f9d1d1;
    border-color: #940000;
    color: #940000;
    position: relative;
    outline: none;
  }

  .ng-invalid.btn {
    &:after {
      content: "Required";
      padding: 2px 5px;
      display: block;
      position: absolute;
      bottom: 100%;
      border-radius: 3px;
      font-size: 0.75em;
      right: 0;
      background-color: #940000;
      color: #fff;
    }
  }
}

.question {
  padding: 0.75em 0;
  border-bottom: 1px dotted #ccc;
  position: relative;

  .check {
    background: rgba(255, 255, 255, 0.5);
    border: 1px solid #8cb9ed;
    background: #e5ecf4;
    padding: 0.5em 1em;
    border-radius: 5px;

    label {
      font-weight: normal;
    }

    input[type="radio"] {
      margin-left: 1em;
    }
  }

  input[type="text"],
  textarea {
    display: block;
    padding: 0.5em;
    width: 100%;
  }

  .validation {
    position: absolute;
    top: 0.5em;
    right: 0;

    &:before {
      cursor: pointer;
      content: "";
      width: 2em;
      height: 2em;
      border: 1px solid #bbb;
      border-radius: 2px;

      display: inline-block;
      background: #fff;
      vertical-align: middle;
      position: absolute;
      top: 0;
      content: "";
      text-align: center;
      color: #bbb;
      font-family: FontAwesome, "FontAwesome";
      line-height: 2;
      right: 0;
    }

    &.is_active {
      &:before {
        background: $brand-1;
        color: white;
        border-color: $brand-1;
      }
    }
  }
}

.radio_control {
  display: inline;

  label {
    font-weight: normal;
  }

  input[type="radio"] {
    margin-left: 1em;
    margin-right: 0.3em;
  }
}

.tab_content {
  clear: both;
  width: 100%;
}

.tabs {
  list-style: none;
  padding: 0;
  margin-bottom: 1em;
  @include clearfix();

  a {
    cursor: pointer;
    padding: 0.5em 0 0.25em 0;
    display: block;
  }

  li {
    display: inline-block;
    margin: 0 1em 0 0;
    border-bottom: 2px solid transparent;

    &.active {
      a {
        color: #111;
        font-weight: bold;
      }
      border-color: $brand-1;
    }
  }
}

.notes {
  padding-top: 0.5em;

  .tabs {
    display: block;
    float: right;
  }

  h4 {
    float: left;
  }

  input[type="text"],
  textarea {
    display: block;
    padding: 0.5em;
    width: 100%;
  }

  select {
    display: block;
    width: 100%;
    padding: 0.5em;
    margin-bottom: 0.5em;
  }
}

.accordian {
  border-bottom: 1px solid #ccc;
  @include clearfix();
  padding: 1em 0;

  .title {
    padding-right: 1em;
    font-weight: bold;

    &:after {
      content: "";
      font-family: FontAwesome;
      float: right;
    }
  }

  .content {
    display: none;
    padding-top: 1em;
  }

  &.is_active {
    .title:after {
      content: "";
    }

    .content {
      display: block;
      @include clearfix();
    }
  }
}

.user_bio {
  vertical-align: top;
  @include clearfix();
  padding-bottom: 1em;

  .user_image {
    width: 5em;
    height: auto;
    float: left;
    border-radius: 50%;
    margin-right: 1em;
  }

  .user_name {
    font-size: 2em;
    font-weight: 300;
  }
}

.patient_details {
  @include clearfix();

  .address {
    width: 50%;
    display: block;
    float: left;
  }
}

.appointment_date_container_row:hover {
  box-shadow: 0 0 5px 5px #bebebe;
}

.appointment_date {
  h2 {
    display: inline-block;
    padding-right: 1em;
  }
  span {
    font-weight: normal;
  }
}
.appointment_date_range {
  white-space: nowrap;
  .btn {
    position: relative;
    top: -0.3em;
  }
}

.submitted .ng-invalid {
  outline: 1px solid #a94442;
}

.submitted .validation.ng-invalid::before {
  outline: 1px solid #a94442;
}

/*------------------------------------*\
  #WIDGETS
\*------------------------------------*/

.widgets {
  @include clearfix();
  display: flex;
  justify-items: stretch;

  &--2 {
    .widget {
      float: left;
      width: calc(50% - 0.5em);

      + .widget {
        margin-left: 1em;
      }
    }
  }

  &--3 {
    .widget {
      float: left;
      display: block;
      width: calc(33.333333% - 0.75em);

      + .widget {
        margin-left: 1em;
      }
    }
  }
}

.widget {
  padding: 1em;
  @include clearfix();
  margin-bottom: 1em;
  word-wrap: break-word;

  h3:first-child {
    margin-top: 0;
  }

  &--doctor {
    img {
      margin-top: -3em;
    }
  }

  &--a_r {
    @include aspect-ratio(1, 0.5);
    position: relative;

    .inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 1em;
    }
  }
  &--top {
    margin-top: 2em;
  }

  &--price {
    margin-top: -1.5em;
    background: #333333;
    color: #fff;
    padding: 1em;

    h1,
    .h1 {
      color: #fff;
    }
  }

  &--destinations {
    border: 1px solid #ccc;
    background: #fff;
    padding: 1em;
    margin-top: 1em;
    max-height: 25em;
    overflow-y: scroll;
    overflow-x: hidden;
    @include styled-scroll();

    h3 {
      padding: 0;
    }
  }

  &--black {
    background: #111;
    color: #fff;
  }

  &--white {
    background: white;
  }

  &--dark_grey {
    background: #373737;
    color: #fff;
  }

  &--grey_border {
    border: 1px solid #ddd;
  }

  &--grey {
    background: #eee;
  }
}

.site-logo {
  width: 7em;
  margin-top: 1em;
}
.site-address {
  address {
    color: $brand-1;
    max-width: 50%;
    margin-top: 1em;
    text-align: right;
    font-size: 0.9em;
  }
  border-bottom: 1px solid $brand-1;
  padding-bottom: 0.5em;
}
.print_only {
  display: none;
}
.btn-print {
  margin-top: 1em;
}

.table--history {
  word-break: normal;
}

@media print {
  .print_only {
    display: block;
  }
  .btn-print {
    display: none;
  }
  .btn {
    display: none;
  }
}

.form_control input[type="checkbox"] {
  margin: 5px 8px;
}

.indented-address {
  margin: 10px 0 10px 12px;
}

.question {
  &.sign-off {
    position: relative;

    @media (max-width: 991px) {
      margin-bottom: -10px;

      .btn {
        margin-bottom: 5px;
      }
    }

    p {
      display: inline-block;
      width: 75%;
      font-size: 14px;
      line-height: 15px;
    }

    .toggle {
      width: 25%;
      position: absolute;
      top: 10px;
      right: 0;
      text-align: right;
    }
  }
}

.warning-message {
  font-size: 14px;

  @media (max-width: 1200px) {
    font-size: 13px;
  }

  @media (max-width: 991px) {
    font-size: 12px;
    margin-bottom: -10px;
  }
}

app-edit-patient-notes {
  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(#fff, 0.7);
  }
}

.typeahead-wrapper {
  position: relative;
  width: 100%;
  typeahead-container {
    position: fixed;
    display: block;
    left: 10px !important;
    top: 54px !important;
  }
}

/*------------------------------------*\
  #ALERTS
\*------------------------------------*/
.alert {
  padding: 0.4em;
  margin-bottom: 0.4em;
  border-radius: 5px;
  vertical-align: middle;

  &:before {
    min-width: 1.2em;
    display: inline-block;
    font-size: 1.4em;
    vertical-align: middle;
  }

  .buttons {
    margin-top: 0.75em;
  }

  &--error,
  &--danger {
    a {
      background: #f24646;
      color: #fff;
    }
    background: #f24646;
    color: #fff;

    &:before {
      content: "";
      font-family: FontAwesome;
    }
  }

  &--success {
    background: #74b14b;
    color: #fff;

    &:before {
      content: "";
      font-family: FontAwesome;
    }
  }

  &--info {
    background: #4b76b1;
    color: #fff;

    &:before {
      content: " ";
      font-family: FontAwesome;
    }
  }

  &--warning {
    background: #f2cc46;
    color: #302e26;

    &:before {
      content: "";
      font-family: FontAwesome;
    }
  }
}

app-order {
  .modal-body {
    .btn-primary {
      margin-left: 5px;
    }

    .inactive {
      p span,
      label,
      input {
        opacity: 0.3;
      }
    }
  }
}

.doctor-link-list {
  li {
    margin-bottom: 15px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
  }
}

.red-text {
  color: red;
}

.alert-dark-red {
  background: darkred;
  color: white;
  font-size: 20px;
}

.key-alert-title {
  background-color: darkred;
  color: white;
  font-size: 20px;
  padding: 1rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.key-alerts-inner-container {
  padding: 1rem;
  border: 3px solid darkred;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.expanded {
  .fieldset_inner {
    max-height: 3000px;
    display: block;
    transition: max-height 3s linear;
  }

  .fieldset_title {
    &:after {
      content: "\f146";
      color: #999;
    }
  }
}

.align-to-left {
  float: right;
}

.lowered {
  bottom: -25px;
}

.radio-btn {
  border: 0px;
  vertical-align: middle;
  width: 1.5em;
  height: 1.5em;
}
.radio_label {
  margin-left: 5px;
}
.lower {
  margin-top: 10px;
}
.bigger {
  margin-bottom: 25px;
}
pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
.break-word {
  word-break: break-word;
}
.consultation-cancellation {
  margin-left: 20px;
}

.consultation-cancellation p:first-child label {
  margin-top: 10px;
}
.consultation-cancellation p label {
  font-weight: 400;
  margin-left: 5px;
}

.original-answer {
  color: #8a00cc;
  font-weight: bold;
}

.original-answer td {
  border-top: none !important;
}
.blocker-answer {
  color: red;
  font-weight: bold;
}

.blocker-answer td {
  border-top: none !important;
}
.action-buttons {
  margin-bottom: 5px;
}

thead {
  word-break: keep-all;
}
th {
  padding: 0 5px;
}

.table-container {
  width: 100%;
  overflow-y: auto;
  margin: 0 0 1em;
}
.table-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 14px;
  height: 14px;
}

.table-container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 3px solid #fff;
  background-color: rgba(0, 0, 0, 0.3);
}

.pagination-active-background {
  background-color: #c9f5cd !important;
}

td.padded-table-cell {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.light-green {
  color: #25a71e !important;
}
.disabled {
  color: grey !important;
  pointer-events: none;
  cursor: not-allowed;
}

@media (min-width: 992px) {
  .modal-xl {
    width: 975px;
  }
}

.enterprise h3 {
  background-color: #139e52;
  color: white;
  padding-top: 0.6em;
  margin-top: 0;
  text-align: center;
}

.enterprise h4 {
  margin-top: -10px;
  text-align: center;
  background-color: #139e52;
  padding-bottom: 1em;
  padding-top: 0.2em;
}
.well-security {
  word-break: break-word;
}
.notes-question {
  margin-top: 0.8em;
  margin-bottom: 0.8em;
}
.pictures-placeholder {
  display: flex;
  margin-top: 1em;
  margin-bottom: 1em;
  justify-content: flex-start;
  overflow-x: scroll;
  height: 170px;
}
.picture-placeholder {
  width: 150px;
  height: 150px;
  min-width: 150px;
  margin-right: 1em;
  margin-left: 1em;
  transition: filter 0.3s ease-out;
}
.picture {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
.picture-placeholder:hover {
  filter: grayscale(0.9);
}

.scr-inputs {
  display: grid;
  justify-items: center;
  align-items: center;
  grid-gap: 5px;
  // grid-template-columns: repeat(6, 1fr);
  grid-template-columns: 1fr 1fr 100px 100px 1fr 1fr;
}
.grid-gender {
  grid-column: span 2;
}
.grid-gender-male {
  justify-self: end;
  margin-right: 2em;
}
.grid-gender-female {
  justify-self: start;
  margin-left: 2em;
}
.radio-label {
  margin-left: 0.5em;
}
.ml-2 {
  margin-left: 2em !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}

.pending-orders-notification {
  display: inline-block;
  line-height: 0px;
  border-radius: 50%;
  border: 1px solid #666;
  margin: auto;
  background: red;
  color: white;
  margin-right: -25px;
  margin-top: -1000px;
}

.pending-orders-notification span {
  display: inline-block;
  padding-top: 50%;
  padding-bottom: 50%;
  margin-left: 8px;
  margin-right: 8px;
}

.btn {
  &:active,
  &:focus {
    outline: none;
  }
}

.patient-response {
  margin: 4rem 10rem;
  padding: 1rem 1rem 2rem;
  background-color: #f3f3f3;
  border-radius: 1rem;

  h4 {
    text-transform: none;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    font-weight: 600;
  }

  span {
    padding: 0.7rem 1rem;
    background-color: rgb(77, 133, 255);
    color: white;
    border-radius: 5px;
    font-weight: 600;
  }

  .patient-statement {
    display: flex;
    column-gap: 1rem;
    align-items: flex-start;

    img {
      width: 3%;
      margin-top: 1rem;
    }
  }
}

.peg-average {
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem 5rem;
  column-gap: 1rem;
  font-size: 2.5rem;
  font-weight: 600;
}

.pagination-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 1rem;

  img {
    height: 2rem;
  }

  .previous {
    transform: rotate(90deg);
  }
  .next {
    transform: rotate(270deg);
  }
}

.recommendations {
  table {
    width: 100%;
    border: 1px solid #ddd;

    tr {
      th {
        padding: 0.5rem;
        background: #f9f9f9;
        border-bottom: 1px solid #ddd;

        p {
          font-weight: bold;
          margin-bottom: 0;
        }
      }

      td {
        border: none;
        padding: 0.5rem;
      }
    }
  }
}

.repeat-reason {
  padding: 1rem;
  background-color: white;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.loading-container {
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
}

.note-score {
  display: flex;
  justify-content: flex-end;
  padding-right: 2rem;
  font-size: 2rem;
  font-weight: 600;
}

.message-modal {
  @media (min-width: 768px) {
    width: 65% !important;
  }

  .message-section {
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    padding: 2px;
    margin-bottom: 2rem;

    .table {
      margin-bottom: 0;

      td {
        .date-bubble {
          font-size: 1.15rem;
          background-color: #e6e6e6;
          width: fit-content;
          padding: 0.1rem 0.5rem;
          border-radius: 15px;
        }

        span {
          white-space: pre-wrap;
        }
      }
    }

    .waiting-bubble {
      padding: 0.5rem;
      border-radius: 5px;
      background-color: #ebebeb;
    }
  }

  .message-form {
    display: flex;
    column-gap: 1rem;
    justify-content: space-between;

    .form-control {
      height: 100%;
    }

    .btn {
      height: 4.5rem;
    }

    textarea {
      width: 100%;
      resize: none;
    }
  }
}
.patient-modal {
  @media (min-width: 768px) {
    width: 65% !important;
  }

  .patient-content {
    margin: 2rem 2rem;
  }

  .peg-average {
    display: flex;
    justify-content: flex-end;
    margin: 0.5rem 5rem;
    column-gap: 1rem;
    font-size: 2.5rem;
    font-weight: 600;
  }
}
.modal-question {
  margin-bottom: 2rem;

  .modal-radios {
    label {
      margin: 0 3rem 0 0.5rem;
    }
  }
}
